import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import InitialPage from "./pages/Initial Page";
import Home from "./pages/home";
import Cursos from "./pages/cursos";
import Services from "./pages/services";
import BecomeAFriend from "./pages/become a friend";
import BecomeAStudent from "./pages/become a student";
import BeforeServices from "./pages/beforeServices";
import CoursesPlatform from "./pages/coursesPlatform";
import WhenServiceIsClicked from "./pages/whenServiceClicked";
import TimeToPayment from "./pages/timeToPayment";
import CreateYourAccount from "./pages/createYourAccount";
import InvitingToCourses from "./pages/invitingToCourses";
import AccountCreatedSuccess from "./pages/accountCreatedSuccess";
import AccountCreatedForFriend from "./pages/accountCreatedForFriend";
import PaymentRealized from "./pages/paymentRealized";

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<InitialPage />}>
          <Route index element={<Home />}></Route>
          <Route path="/cursos" element={<Cursos />}></Route>
          <Route
            path="/:platform/:action"
            element={<WhenServiceIsClicked />}
          ></Route>
          <Route
            path="/:platform/:action/:name/:street/:number/:complement/:neighborhood/:city/:state/:cep?/:whatsApp/:email?/:optionHour"
            element={<Services />}
          ></Route>
          <Route
            path="/:platform/:action/:friendName"
            element={<TimeToPayment />}
          ></Route>
          <Route path="/createYourAccount" element={<CreateYourAccount />}></Route>
          <Route path="/:coursesPlatform" element={<CoursesPlatform />}></Route>
          <Route path="/becomeAFriend" element={<BecomeAFriend />}></Route>
          <Route path="/becomeAStudent" element={<BecomeAStudent />}></Route>
          <Route path="/beforeServices" element={<BeforeServices />}></Route>
          <Route path="/invitingToCourses" element={<InvitingToCourses />}></Route>
          <Route path="/accountCreatedSuccess" element={<AccountCreatedSuccess />}></Route>
          <Route path="/accountCreatedForFriend" element={<AccountCreatedForFriend />}></Route>
          <Route path="/paymentRealized" element={<PaymentRealized/>}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
