import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import mapStyle from "./style.css";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
import friendPerson from "../../data/friendPerson";

const position = [-23.55052, -46.633308];
// const markerPosition = [-23.54636, -46.62760];

export default function Map() {
  return (
    <div className={`${mapStyle}`} id="map">
      <MapContainer
        center={position}
        zoom={15}
        scrollWheelZoom={false}
        style={{ height: 536 }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {friendPerson.map((person) => (
          <Marker
            key={person.name}
            position={person.position}
            icon={
              new Icon({
                iconUrl: markerIconPng,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
              })
            }
          >
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
}
