const visibleOrInvisible = (platform) => {
  const list = document.querySelectorAll(`.platform-${platform}`);
  list.forEach((li) => {
    if (li.classList.contains("invisible")) {
      li.classList.remove("invisible");
      return;
    }
    li.classList.add("invisible");
  });
};

export { visibleOrInvisible };
