import footerStyle from "./style.css";

export default function Footer() {
  return (
    <section className={`adjustFooter ${footerStyle}`}>
      <footer>
        <h1>Sobre a Digital Delivery</h1>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam
          quibusdam odit velit cupiditate quos vero eveniet, reprehenderit
          architecto laudantium quaerat delectus ratione rem hic culpa porro.
          Obcaecati ad voluptates adipisci?
        </p>
        <div className="allImagesSize">
          <img src="/instagramFooter.png" alt="Ícone do Instagram" />
          <img src="/linkedinFooter.png" alt="Ícone do LinkedIn" />
          <img src="/facebookFooter.png" alt="Ícone do LinkedIn" />
          <img src="/tiktokFooter.png" alt="Ícone do LinkedIn" />
        </div>
      </footer>
    </section>
  );
}
