import Footer from "../../components/footer";
import Header from "../../components/header";
import accountCreatedForFriend from "./style.css";

export default function AccountCreatedForFriend() {
  return (
    <div className={`${accountCreatedForFriend}`}>
      <Header />
      <div className="divForCenterCreatedFriend">
        <div className="adjustingAccountCreatedFriend">
          <h1>Sua conta será avaliada</h1>
          <p>Dentro de 2 dias enviaremos um email para testarmos se você está apto para se tornar um de nossos amigos digitais. Clique <a href="/">aqui</a> para voltar para a página inicial.</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}