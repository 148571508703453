import Footer from "../../components/footer";
import Header from "../../components/header";
import accountCreateSuccessStyle from "./style.css";

export default function AccountCreatedSuccess() {
  return (
    <div className={`${accountCreateSuccessStyle}`}>
      <Header />
      <div className="divForCenterCreatedSuccess">
        <div className="adjustingAccountCreatedSuccess">
          <h1>Conta Criada com sucesso!</h1>
          <p>Clique <a href="/">aqui</a> para ser redirecionado para a página inicial.</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}