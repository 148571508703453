  // const handleSubmitRecaptcha = (e) => {
  //   e.preventDefault();
  //   console.log("Token do ReCAPTCHA:", recaptchaToken);
  // };

  const handleRecaptchaChangeFirst = (setRecaptchaToken) =>{
  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };
  handleRecaptchaChange();
}
  export {handleRecaptchaChangeFirst};