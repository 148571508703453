import { useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import InputGeneric from "../../components/inputGeneric";
import LabelGeneric from "../../components/labelGeneric";
import WhatsAppIcon from "../../components/whatsAppIcon";
import friendStyle from "./style.css";
import { handleChangeAddress } from "../../utils/handleChangeAddress";
import RecaptchaGoogle from "../../components/recaptchaGoogle";
import ButtonGeneric from "../../components/buttonGeneric";
import {
  confirmingAccount,
  registeringFunction,
} from "../../utils/registeringFunction";

export default function BecomeAFriend() {
  const [address, setAddress] = useState({
    name: "",
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    cep: "",
    whatsApp: "",
    email: "",
    password: "",
  });
  const [values, setValues] = useState([0, 0, 0, 0, 0]);
  const platformsName = [
    "Instagram:",
    "Facebook:",
    "Tiktok:",
    "Twitter:",
    "Google:",
  ];

  const rangeInputHandleChange = (index, newValue) => {
    const updatedValues = [...values];
    updatedValues[index] = newValue;
    setValues(updatedValues);
  };

  return (
    <div className={` ${friendStyle}`}>
      <Header
        thisHeaderStyle={`thisHeaderStyleBecomeFriend`}
        divSeparingStyleHeader={`divSeparingStyleHeaderBecomeFriend`}
      />
      <div className="beOurGreatFriend">
        <h1>Seja um dos nossos grandiosos amigos</h1>
      </div>
      <div className="backgroundOfServiceBecomeFriend"></div>
      <div className="adjustingImageAndTitleBecomeFriend">
        <img
          src="/friendSimbolBecomeFriend.png"
          alt=""
          className="friendSimbolBecomeFriend"
        />
        <h1 className="forHBecomeFriend2">Torne-se um amigo</h1>
      </div>
      <div className="adjustingDivForForFriend">
        <form className="adjustingFormToBecameFriend">
          <div className="adjustingLabelAndInput20">
            <LabelGeneric>
              <span className="textWithSameSize30">Nome: </span>
              <InputGeneric
                classTypeName="name"
                typeInput={"text"}
                valueInput={address.name}
                setValueInput={(value) =>
                  handleChangeAddress(setAddress, "name", value)
                }
                typeInputId="longestTextFriend"
              />
            </LabelGeneric>
            <div className="adjustTogheter30">
              <LabelGeneric>
                <span className="textWithSameSize30">Endereço: </span>
                <InputGeneric
                  classTypeName="street"
                  typeInput={"text"}
                  valueInput={address.street}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "street", value)
                  }
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize30 textWithSameSize40">
                  Número:{" "}
                </span>
                <InputGeneric
                  classTypeName="number"
                  typeInput={"text"}
                  valueInput={address.number}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "number", value)
                  }
                  typeInputId="shorterTextFriend"
                />
              </LabelGeneric>
            </div>
            <div className="adjustTogheter30">
              <LabelGeneric>
                <span className="textWithSameSize30">Complemento: </span>
                <InputGeneric
                  classTypeName="complement"
                  typeInput={"text"}
                  valueInput={address.complement}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "complement", value)
                  }
                  typeInputId="mediumTextFriend"
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize30 textWithSameSize40">
                  Bairro:{" "}
                </span>
                <InputGeneric
                  classTypeName="neighborhood"
                  typeInput={"text"}
                  valueInput={address.neighborhood}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "neighborhood", value)
                  }
                  typeInputId="largeTextFriend"
                />
              </LabelGeneric>
            </div>
            <div className="adjustTogheter30">
              <LabelGeneric>
                <span className="textWithSameSize30">Cidade: </span>
                <InputGeneric
                  classTypeName="city"
                  typeInput={"text"}
                  valueInput={address.city}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "city", value)
                  }
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize30 textWithSameSize40">
                  Estado:{" "}
                </span>
                <InputGeneric
                  classTypeName="state"
                  typeInput={"text"}
                  valueInput={address.state}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "state", value)
                  }
                  typeInputId="shorterTextFriend"
                />
              </LabelGeneric>
            </div>
            <div className="adjustAtStart30">
              <LabelGeneric>
                <span className="textWithSameSize30">CEP: </span>
                <InputGeneric
                  classTypeName="cep"
                  typeInput={"text"}
                  valueInput={address.cep}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "cep", value)
                  }
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize30">WhatsApp: </span>
                <InputGeneric
                  classTypeName="whatsApp"
                  typeInput={"text"}
                  valueInput={address.whatsApp}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "whatsApp", value)
                  }
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize30">Email: </span>
                <InputGeneric
                  classTypeName="email"
                  typeInput={"text"}
                  valueInput={address.email}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "email", value)
                  }
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize30">Senha: </span>
                <InputGeneric
                  classTypeName="password"
                  typeInput={"text"}
                  valueInput={address.password}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "password", value)
                  }
                />
              </LabelGeneric>
            </div>
            {values.map((value, index) => (
              <div className="adjustStartBecomeFriend">
                <LabelGeneric key={index}>
                  <span className="textWithSameSize30 textPaddingPlatformsNameBecomeFriend">
                    {platformsName[index]}
                  </span>
                  <div className="rangeWrapper">
                    <InputGeneric
                      list={`values`}
                      typeInput="range"
                      min={0}
                      max={10}
                      value={value}
                      setValueInput={(e) =>
                        rangeInputHandleChange(index, e.target)
                      }
                    />
                    <datalist id="values">
                      <option value="0" label="0"></option>
                      <option value="5" label="5"></option>
                      <option value="10" label="10"></option>
                    </datalist>
                    <div className="rangeLabels">
                      <span className="rangeLabel">0</span>
                      <span className="rangeLabel">5</span>
                      <span className="rangeLabel">10</span>
                    </div>
                  </div>
                </LabelGeneric>
              </div>
            ))}
            <div>
              <p>
                Nos diga as suas 3 melhores características para ser um amigo
                digital:
              </p>
              <InputGeneric
                classTypeName="inputValueForText3Powers"
                typeInput={`text`}
              ></InputGeneric>
            </div>
            <div className="recaptchaAndCheckmarkLine30">
              <RecaptchaGoogle />
              <div className="thisCheckMarkStyling30"></div>
            </div>
            <ButtonGeneric
              inputType={"button"}
              onClick={() =>
                registeringFunction(
                  confirmingAccount,
                  "/accountCreatedForFriend",
                )
              }
            >
              Criar Conta
            </ButtonGeneric>
          </div>
        </form>
      </div>
      <WhatsAppIcon />
      <Footer />
    </div>
  );
}
