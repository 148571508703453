import { Link, useParams } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import TimeToPayStyle from "./style.css";
import LabelGeneric from "../../components/labelGeneric";
import CheckMarkGeneric from "../../components/checkMarkGeneric";
import InputGeneric from "../../components/inputGeneric";
import { useState } from "react";
import { handleChangeAddress } from "../../utils/handleChangeAddress";
import { handlePaymentOptionChange } from "../../utils/handlePaymentOptionChange";
import ButtonGeneric from "../../components/buttonGeneric";
export default function TimeToPayment() {
  const optionsToPay = [
    { value: "option1", label: "pix" },
    { value: "option2", label: "cartão de crédito" },
  ];
  const { platform, action, friendName } = useParams();
  const [cardInformation, setCardInformation] = useState({
    numberCard: "",
    dateCard: "",
    CVCCard: "",
    holderCard: "",
  });
  const [paymentOption, setPaymentOption] = useState('');

  return (
    <div className={TimeToPayStyle}>
      <Header thisHeaderStyle={`thisHeaderStylePayment`} divSeparingStyleHeader={`divSeparingStyleHeaderPayment`} />
      <div className="backgroundOfServicePayment"></div>

      <h1>Pagar {friendName}</h1>
      <LabelGeneric>
        <CheckMarkGeneric options={optionsToPay} onChange={(e) => handlePaymentOptionChange(e.target.value, setPaymentOption)} />
      </LabelGeneric>
      {paymentOption === 'option2' && (
        <form className="formForTimeToPayment">
          <div className="divIntoFormPayment">
            <h4>Dados do Cartão:</h4>
            <InputGeneric
              placeholder="1254 1222 1334 1134"
              classTypeName="numberCard"
              typeInput="text"
              valueInput={cardInformation.numberCard}
              setValueInput={(value) =>
                handleChangeAddress(setCardInformation, "numberCard", value)
              }
            />
            <div className="adjustingDateAndCVC">
              <InputGeneric
                placeholder="MM / AA"
                classTypeName="dateCard"
                typeInput="text"
                valueInput={cardInformation.dateCard}
                setValueInput={(value) =>
                  handleChangeAddress(setCardInformation, "dateCard", value)
                }
              />
              <InputGeneric
                placeholder="CVC"
                classTypeName="CVCCard"
                typeInput="text"
                valueInput={cardInformation.CVCCard}
                setValueInput={(value) =>
                  handleChangeAddress(setCardInformation, "CVCCard", value)
                }
              />
            </div>
            <h4>Nome do titular do cartão:</h4>
            <InputGeneric
              placeholder={`Nome Completo`}
              classTypeName="holderCard"
              typeInput="text"
              valueInput={cardInformation.holderCard}
              setValueInput={(value) =>
                handleChangeAddress(setCardInformation, "holderCard", value)
              }
            />
          </div>
        </form>
      )}
      {paymentOption === 'option1' && (
        <form className="formForPixPayment">
          <div className="adjustingInsideFormPix">
            <h4>Escaneie o QRCode ou digite a chave:</h4>
            <div className="qrCodePix">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Link_pra_pagina_principal_da_Wikipedia-PT_em_codigo_QR_b.svg/1200px-Link_pra_pagina_principal_da_Wikipedia-PT_em_codigo_QR_b.svg.png" alt="" />
            </div>
            <div className="adjustingKeyForm">
              <h4>Chave: </h4><span className="keyForPix"> XXXX - XXXX - XXXX</span>
            </div>
          </div>
        </form>
      )}
      {(paymentOption === 'option1' || paymentOption === 'option2') && (
        <Link to={`/paymentRealized`}>
          <ButtonGeneric classType={`buttonClassTimePayment`}>Realizar Pagamento</ButtonGeneric>
        </Link>
      )}
      <Footer />
    </div>
  );
}
