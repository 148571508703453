import Footer from "../../components/footer";
import Header from "../../components/header";
import beforeServicesStyle from "./style.css";
import services from "../../data/services";
import { Link } from "react-router-dom";
import { visibleOrInvisible } from "../../utils/visibleOrInvisible";
import InputGeneric from "../../components/inputGeneric";
import { useState } from "react";
import { searchService } from "../../utils/searchService";
import WhatsAppIcon from "../../components/whatsAppIcon";

export default function BeforeServices() {
  const [useService, setService] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPlatform, setSearchPlatform] = useState("");

  return (
    <div className={beforeServicesStyle}>
      <Header />
      <div className="centeringImageAndText2">
        <div className="backgroundOfService2">
          <div className="ladyAndServicePadding2 positionInputSearch2">
            <h1>Serviços</h1>
            <div className="oneSearchItem2">
              <InputGeneric
                typeInput="search"
                valueInput={useService}
                setValueInput={setService}
                searchPlatform={useService}
              />
              <span
                onClick={() =>
                  searchService(useService, setSearchResults, setSearchPlatform)
                }
                className="searchIcon"
              >
                 <img
                    src="/searchIcon.svg"
                    alt="ícone de pesquisa"
                    className="searchIconBeforeServices"
                  />
              </span>
            </div>
            <ul>
              {searchResults.map((action) => (
                <li key={action.title}>
                  <Link
                    className="removingDecorationServices2"
                    to={`/${searchPlatform}/${action.title}`}
                  >
                    {action.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="ladyAndServicePadding2">
            <h1 className="slideRight2">
              Veja o que nossos amigos digitais oferecem
            </h1>
            <img
              src="./senhora andando2.gif"
              alt="senhora andando"
              className="senhoraAndando2"
            />
          </div>
        </div>
        <div className="adjustingBlock2">
          {services.map((service) => (
            <ul key={service.platform} className="adjustUlStyle2">
              <div
                className="adjustingItems2"
                onClick={(e) => visibleOrInvisible(service.platform)}
              >
                <img
                  className="adjustingIcons2"
                  src={service.imagePlatform}
                  alt={service.platform}
                />
                <h1>{service.platform}</h1>
              </div>
              {service.actions.map((serv) => (
                <li
                  key={serv.title}
                  className={`platform-${service.platform} invisible paddingBottomLi`}
                >
                  <Link
                    className="removingDecorationServices3"
                    to={`/${service.platform}/${serv.title}`}
                  >
                    {serv.title}
                  </Link>
                </li>
              ))}
            </ul>
          ))}
        </div>
        <WhatsAppIcon />
      </div>
      <Footer />
    </div>
  );
}
