import Footer from "../../components/footer";
import Header from "../../components/header";
import WhatsAppIcon from "../../components/whatsAppIcon";
import courses from "../../data/courses";
import courseStyle from "./style.css";

export default function Cursos() {
  return (
    <div className={` ${courseStyle}`}>
      <Header />
      <h1>Cursos</h1>
      <div className="centeringCourseName">
        <div className="adjustingCourseTemplate">
          {courses.map((course) => (
            <div className="adjustingCourses" key={course.coursePlatform}>
              <h2>{course.coursePlatform}</h2>
              <a href={`/${course.coursePlatform}`}>
                <img src={course.imageUrl} alt={course.coursePlatform} />
              </a>
            </div>
          ))}
        </div>
      </div>
      <WhatsAppIcon />
      <Footer />
    </div>
  );
}
