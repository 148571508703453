import courses from "../../data/courses";

const searchCourse = (coursesPlatform, setSearchCoursePlatform, setSearchCourseResults) => {
  const results = courses.find(course => course.coursePlatform.toLowerCase() === coursesPlatform.toLowerCase());
  if (results) {
    setSearchCoursePlatform(results.coursePlatform);
    setSearchCourseResults(results.courseActions);
  } else {
    setSearchCoursePlatform(null);
    setSearchCourseResults([]);
  }
};

export { searchCourse };