import services from "../../data/services";

const searchService = (platform, setSearchResults, setSearchPlatform) => {
  const results = services.find(
    (service) => service.platform.toLowerCase() === platform.toLowerCase(),
  );
  if (results) {
    setSearchPlatform(results.platform);
    setSearchResults(results.actions);
  } else {
    setSearchPlatform(null);
    setSearchResults([]);
  }
};

export { searchService };
