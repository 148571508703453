import whatsAppIcon from './style.css';

export default function WhatsAppIcon(){
  return(
    <a
            href="https://api.whatsapp.com/send?phone=5517997319219&text=Olá%20Digital%20Delivery!"
            target="_blank"
            rel="noreferrer" className={`${whatsAppIcon}`}
          >
            <img
              src="/whatsappSimbol.png"
              alt="símbolo do WhatsApp"
              className="whatsAppIcon"
            />
          </a>
  )
}