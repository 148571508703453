/* global grecaptcha */
let confirmingAccount = "";
const registeringFunction = (confirmingAccount,urlAfterRegister) => {
  const fields = [
    "name",
    "street",
    "number",
    "complement",
    "neighborhood",
    "city",
    "state",
    "cep",
    "whatsApp",
    "email",
    "password",
  ];
  const form = document.querySelector(".myForm"); // eslint-disable-line no-unused-vars, @typescript-eslint/no-unused-vars
  let hasError = false;

  fields.forEach((field) => {
    const inputField = document.querySelector(`.${field}`);
    if (inputField) {
      const value = inputField.value.trim();
      if (!value) {
        hasError = true;
        inputField.classList.add("error");
      } else {
        inputField.classList.remove("error");
      }
    } else {
      console.error(`Elemento "${field}" não encontrado.`);
    }
  });

  const recaptchaValue = grecaptcha.getResponse();
  if (!recaptchaValue) {
    alert("Por favor, selecione o reCAPTCHA!");
    return;
  }

  if (hasError) {
    alert("Dados faltando!");
    confirmingAccount = "Dados faltando!";
  } else {
    // alert("Dados corretos!");
    confirmingAccount = "Dados corretos!";
    if (urlAfterRegister) {
      window.location.href = urlAfterRegister;
    }
  }
};

export { registeringFunction, confirmingAccount };
