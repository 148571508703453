const handleSaveToLocalStorage = (setIsChecked,isChecked,address) => {
  setIsChecked(isChecked = true);
  const addressData = JSON.stringify(address);
  // const soData = JSON.stringify(
  //   optionsSO.find((option) => option.label === selectedOptionSO),
  // );
//     localStorage.setItem('groupSO', soData);
// console.log(soData)
  localStorage.setItem("addressData", addressData);
  // localStorage.setItem("selectedOption", soData);
};

export {handleSaveToLocalStorage};