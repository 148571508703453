import inputGenericStyle from "./style.css";
export default function InputGeneric({classTypeName='',typeInputId='', typeInput, valueInput, setValueInput,placeholder,min,max ,list}) {
  return (
    <input
    placeholder={placeholder}
      className={`${inputGenericStyle} ${classTypeName}`}
      id={typeInputId}
      type={typeInput}
      value={valueInput}
      onChange={(Event) => setValueInput(Event.target.value)}
      min={min}
      max={max}
      list={list}
    />
  );
}
