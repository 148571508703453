import courses from "../../data/courses";

const backgroundChange = (coursesPlatform) => {
  const course = courses.find(course => course.coursePlatform === coursesPlatform);

  if (course) {
    const backgroundForThisCourse = document.querySelector('.backgroundForThisCourse');
    if (backgroundForThisCourse) {
      const style = document.createElement('style');
      style.textContent = `.backgroundForThisCourse::before{
            background-image:url(${course.backgroundThisCourse});
          }`;
      document.head.append(style);
    }
  }
};

export { backgroundChange };