import { useEffect, useState } from "react";
import HeaderStyle from "./style.css";

export default function Header({ thisHeaderStyle, divSeparingStyleHeader,classNameHamburguerMenu,burguerMenuIsOpenClass, classNameAnchorStudentAndFriend,hamburguerClassHeader,hamburguerMenuClassHeader,headerLogo,separingLogoHeader }) {
  const [isBurguer, setIsBurguer] = useState(false);

  useEffect(() => {
    if (isBurguer) {
      const elements = document.querySelectorAll(".invisibleWhenBurguerIsOn");
      elements.forEach((element) => {
        element.style.display = "none";
      });

      const htmlElements = document.querySelectorAll("html");
      htmlElements.forEach((element) => {
        const htmlWidth = element.clientWidth || window.innerWidth;
        if (htmlWidth < 290) {
          element.style.overflowX = "auto";
        } else {
          element.style.overflowX = "hidden";
        }
        element.style.overflowY = "hidden";
      });

      return () => {
        elements.forEach((element) => {
          element.style.display = "";
        });
        htmlElements.forEach((element) => {
          element.style.overflowY = "";
          element.style.overflowX = "";
        });
      };
    }
  }, [isBurguer]);

  return (
    <div className={`thisHeaderStyleEspecificDivAll ${isBurguer ? 'justTheSizeAdjustingHeader' : 'justTheSizeAdjustingHeaderForOtherViews'}`}>
      <header className={`${HeaderStyle} ${thisHeaderStyle} thisHeaderStyleEspecific`}>
        <div className={`${divSeparingStyleHeader} separingItems`}>
          <div className={`separingLogo ${separingLogoHeader}`}>
            <img src="/logoDD.png" alt="Logo Digital Delivery" className={`logo ${headerLogo}`} />
          </div>
          <div className="adjustItems">
            <a href="/" className="lowerWidth">
              Página Inicial
            </a>
            <a href="/becomeAStudent" className={`ancorBecomeStudent ${classNameAnchorStudentAndFriend}`}><span className="textHeaderOneLine">Torne-se</span> <span className="textHeaderOneLine">um Aluno</span></a>
            <a href="/becomeAFriend" className={`ancorBecomeFriend ${classNameAnchorStudentAndFriend}`}><span className="textHeaderOneLine">Torne-se</span> <span className="textHeaderOneLine">um Amigo</span></a>
            <div
              className={`showBurguerMenu a ${isBurguer ? `burguerMenuIsOpen ${burguerMenuIsOpenClass}` : ""}`}
            >
              {isBurguer ? (
                <div className={`hamburguerMenuAdjustedOpen ${classNameHamburguerMenu}`}>
                  <div onClick={() => setIsBurguer(false)} className="adjustingCloseItemMenu">
                    <img src="/iconClose.png" alt="Botão de fechar" className="adjustingCloseIcon" />
                  </div>
                  <div className="adjustingOptionsMenuForAll">
                    <a href="/" className="adjustingOptionsMenu">
                      Página Inicial
                    </a>
                    <a href="/createYourAccount" className="adjustingOptionsMenu">
                      Crie sua conta
                    </a>
                    <a href="/invitingToCourses" className="adjustingOptionsMenu">
                      Cursos
                    </a>
                    <div className="a adjustingOptionsMenu">Sair</div>
                  </div>
                </div>
              ) : (
                <div className={`necessaryForHambuguerAdjustHeader ${hamburguerClassHeader}`}>
                  <div className={`hamburguerMenuHeader ${hamburguerMenuClassHeader}`} onClick={() => setIsBurguer(true)}>≡</div>
                </div>
              )}
            </div>
            <div className="menuOverlay"></div>
            <a href="/createYourAccount" className="lowerWidth">
              Crie sua conta
            </a>
            <a href="/invitingToCourses" className="lowerWidth">
              Cursos
            </a>
            <div className="lowerWidth a">Sair</div>
          </div>
        </div>
      </header>
      </div>
    
  );
}
