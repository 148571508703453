import paymentRealizedStyle from "./style.css";
import Header from "../../components/header";
import Footer from "../../components/footer";

export default function PaymentRealized() {
  return (
    <div className={`${paymentRealizedStyle}`}>
      <Header />
      <div className="divForPaymentRealized">
      <div className="adjustingPaymentRealizedInformation">
        <h1>Pagamento realizado com Sucesso!</h1>
        <p>O amigo digital já está a caminho de sua localização! Clique <a href="/">aqui</a> para retornar a página inicial.</p>
      </div>
      </div>
      <Footer />
    </div>
  )
}