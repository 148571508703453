import Footer from "../../components/footer";
import Header from "../../components/header";
import InvitingCoursesStyle from "./style.css";
import ButtonGeneric from "../../components/buttonGeneric";
import { useState } from "react";
import InputGeneric from "../../components/inputGeneric";
import { handleChangeAddress } from "../../utils/handleChangeAddress";
import LabelGeneric from "../../components/labelGeneric";
import CheckMarkGeneric from "../../components/checkMarkGeneric";
import { handlePaymentOptionChange } from "../../utils/handlePaymentOptionChange";
import { changeVisibilityCheckingEmail } from "../../utils/changeVisibilityCheckingEmail";
import { letElementTrueToShow } from "../../utils/letElementTrueToShow";
import { Link } from "react-router-dom";
import WhatsAppIcon from "../../components/whatsAppIcon";

export default function InvitingToCourses() {
  let [isCoursesShowing, setIsCoursesShowing] = useState("");
  const [cardInformation, setCardInformation] = useState({
    numberCard: "",
    dateCard: "",
    CVCCard: "",
    holderCard: "",
  });
  const optionsToPay = [
    { value: "option1", label: "pix" },
    { value: "option2", label: "cartão de crédito" },
  ];
  const [paymentOption, setPaymentOption] = useState("");
  const [isVerifiedEmail, setIsVerifiedEmail] = useState("");
  // let isEmailAlreadyRegistered = false;
  let isEmailAlreadyRegistered = true;

  return (
    <div className={`${InvitingCoursesStyle} allBackgroundInvitingCourses`}>
      <Header thisHeaderStyle={`backgroundOfServiceInvitingCourses`} divSeparingStyleHeader={`adjustingTopHeaderInvitingCourses`} classNameHamburguerMenu={"classNameHamburguerMenu"}/>
      <div className="beOurGreatInvitingCourses">
        <h1>Seja um dos nossos grandiosos amigos</h1>
      </div>
      {/* <div className="backgroundOfServiceInvitingCourses"></div> */}
      <div className="principalDivInvitingCourses">
        <div className="adjustingDivInvitingCourses">
          <div className="adjustingImageAndTitleInvitingCourses">
            <img src="/aproovedSimbolInvitingCourses.png" alt="" className="aproovedInvitingCourses" />
            <h1>Plataforma de Cursos Digital Delivery</h1>
          </div>
          <p>
            Olá, você está acessando a aba Cursos, que tal ter acesso a um
            catálogo imenso de curso com alto nível de qualidade por apenas R$
            19,90?
          </p>
          <ButtonGeneric inputType={`button`} classType={`buttonStyleForInvitingToCourses`}
            onClick={() =>
              letElementTrueToShow(setIsVerifiedEmail, isVerifiedEmail)
            }
          >
            Veja nossos cursos
          </ButtonGeneric>
          {isVerifiedEmail ? (
            <>
              <div className="anotherColorFormInvitingCourses">
                <p>
                  Antes de qualquer coisa, digite seu email para verificar se
                  você já possui uma conta.
                </p>
                <InputGeneric classTypeName="inputInvitingToCoursesEmail" setValueInput={(e) => e.target} />
                <ButtonGeneric
                  onClick={() =>
                    changeVisibilityCheckingEmail(isEmailAlreadyRegistered, setIsCoursesShowing, isCoursesShowing)
                  }
                >
                  Verificar email
                </ButtonGeneric>
              </div>
              <div className="anotherMessageToShowEmail"></div>
            </>
          ) : (
            <></>
          )}
        </div>
        {isCoursesShowing ? (
          <div className="showingInvitingCourses">
            <h1>Pagar</h1>
            <LabelGeneric>
              <CheckMarkGeneric
                options={optionsToPay}
                onChange={(e) =>
                  handlePaymentOptionChange(e.target.value, setPaymentOption)
                }
              />
            </LabelGeneric>
            <div>
              {" "}
              {paymentOption === "option2" && (
                <form className="formForTimeToInvitingCourses">
                  <div className="divIntoFormPaymentInvitingCourses">
                    <h4>Dados do Cartão:</h4>
                    <InputGeneric
                      placeholder="1254 1222 1334 1134"
                      classTypeName="numberCard"
                      typeInput="text"
                      valueInput={cardInformation.numberCard}
                      setValueInput={(value) =>
                        handleChangeAddress(
                          setCardInformation,
                          "numberCard",
                          value,
                        )
                      }
                    />
                    <div className="adjustingDateAndCVCInvitingCourses">
                      <InputGeneric
                        placeholder="MM / AA"
                        classTypeName="dateCard"
                        typeInput="text"
                        valueInput={cardInformation.dateCard}
                        setValueInput={(value) =>
                          handleChangeAddress(
                            setCardInformation,
                            "dateCard",
                            value,
                          )
                        }
                      />
                      <InputGeneric
                        placeholder="CVC"
                        classTypeName="CVCCard"
                        typeInput="text"
                        valueInput={cardInformation.CVCCard}
                        setValueInput={(value) =>
                          handleChangeAddress(
                            setCardInformation,
                            "CVCCard",
                            value,
                          )
                        }
                      />
                    </div>
                    <h4>Nome do titular do cartão:</h4>
                    <InputGeneric
                      placeholder={`Nome Completo`}
                      classTypeName="holderCard"
                      typeInput="text"
                      valueInput={cardInformation.holderCard}
                      setValueInput={(value) =>
                        handleChangeAddress(
                          setCardInformation,
                          "holderCard",
                          value,
                        )
                      }
                    />
                  </div>
                </form>
              )}
              {paymentOption === "option1" && (
                <form className="formForPixPaymentInvitingCourses">
                  <div className="adjustingInsideFormPixInvitingCourses">
                    <h4>Escaneie o QRCode ou digite a chave:</h4>
                    <div className="qrCodePix">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Link_pra_pagina_principal_da_Wikipedia-PT_em_codigo_QR_b.svg/1200px-Link_pra_pagina_principal_da_Wikipedia-PT_em_codigo_QR_b.svg.png"
                        alt=""
                      />
                    </div>
                    <div className="adjustingKeyFormInvitingCourses">
                      <h4>Chave: </h4>
                      <span className="keyForPix"> XXXX - XXXX - XXXX</span>
                    </div>
                  </div>
                </form>
              )}{" "}
              <Link to={`/cursos`}>
                <ButtonGeneric typeInput={`button`} classType={`buttonInvitingCoursesRealizePayment`}>Clique para realizar o pagamento</ButtonGeneric>
              </Link>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <WhatsAppIcon />
      <Footer />
    </div>
  );
}
