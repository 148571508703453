import HomeStyle from "./style.css";
import Header from "../../components/header";
import InputGeneric from "../../components/inputGeneric";
import { useState } from "react";
import { searchService } from "../../utils/searchService";
import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import ButtonGeneric from "../../components/buttonGeneric";
import WhatsAppIcon from "../../components/whatsAppIcon";

export default function Home() {
  const [useService, setService] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPlatform, setSearchPlatform] = useState("");
  return (
    <div className={` ${HomeStyle}`}>
      <Header separingLogoHeader={`separingLogoHeaderForHome`} headerLogo={`headerLogoForHome`} hamburguerClassHeader={`hamburguerForHome`} hamburguerMenuClassHeader={`hamburguerMenuForHome`} classNameAnchorStudentAndFriend={`classNameAnchorForHome`} thisHeaderStyle={`backgroundOfServiceHome`} divSeparingStyleHeader={`adjustingTopHeaderHome`}/>
      <div className="invisibleWhenBurguerIsOn">
        <div className="centeringImageAndText">
          <div className="backgroundOfService">
            <div className="ladyAndServicePadding positionInputSearch">
              <div className="adjustingNames">
                <h1>Serviços</h1>
                <p>Digite o nome da plataforma que deseja para receber o serviço.</p>
              </div>
              <div className="oneSearchItem">
                <InputGeneric classTypeName="inputGenericHomeToService"
                  typeInput="search"
                  valueInput={useService}
                  setValueInput={setService}
                  searchPlatform={useService}
                />
                <span
                  onClick={() =>
                    searchService(
                      useService,
                      setSearchResults,
                      setSearchPlatform,
                    )
                  }
                  className="searchIcon"
                >
                  <img
                    src="/searchIcon.svg"
                    alt="ícone de pesquisa"
                    className="searchIcon"
                  />
                </span>
              </div>
              <ul>
                {searchResults.map((action) => (
                  <li key={action.title}>
                    <Link
                      className="removingDecorationServices"
                      to={`/${searchPlatform}/${action.title}`}
                    >
                      {action.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="ladyAndServicePadding">
              <h1 className="slideRight">Conectando gerações no digital</h1>
              <img
                src="./senhora andando2.gif"
                alt="senhora andando"
                className="senhoraAndando"
              />
            </div>
          </div>
          <section>
            <div className="separingTextFromImage2">
              <h1 className="titleForSectionHome">Veja nossos serviços e contate um amigo</h1>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam
                quibusdam odit velit cupiditate quos vero eveniet, reprehenderit
                architecto laudantium quaerat delectus ratione rem hic culpa
                porro. Obcaecati ad voluptates adipisci?
              </p>
              <a href="/beforeServices">
                <ButtonGeneric inputType="button">Contate</ButtonGeneric>
              </a>
            </div>
            <img
              src="/howToContact2.png"
              alt="como contatar"
              className="imagesGroupSmartphone imageFloating"
            />
          </section>
          <section className="reverseImage">
            <div className="separingTextFromImage">
              <h1 className="titleForSectionHome">Cursos</h1>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam
                quibusdam odit velit cupiditate quos vero eveniet, reprehenderit
                architecto laudantium quaerat delectus ratione rem hic culpa
                porro. Obcaecati ad voluptates adipisci? Clique no botão para
                ser redirecionado.
              </p>
              <a href="/cursos">
                <ButtonGeneric inputType="button">Assista</ButtonGeneric>
              </a>
            </div>
            <img
              src="/coursesImage2.png"
              alt="cursos"
              className="imagesGroup imageFloating "
            />
          </section>
          <WhatsAppIcon />
        </div>
        <Footer />
      </div>
    </div>
  );
}
