const confirmService = (checkFormStatus, platform, action, encodedName, encodedStreet, encodedNumber, encodedComplement, encodedNeighborhood, encodedCity, encodedState, encodedCep, encodedWhatsApp, encodedEmail, encodedSelectedOptionHour) => {
  if (checkFormStatus === "Dados corretos!") {
    const checkMarkOptions = document.querySelectorAll('.checkbox-list input[type="radio"]');
    let checkMarkSelected = false;
    checkMarkOptions.forEach(option => {
      if (option.checked) {
        checkMarkSelected = true;
      }
    });

    if (!checkMarkSelected) {
      alert("Dados faltando e/ou opção não selecionada!");
      return;
    }

    const secondCheckMarkOptions = document.querySelectorAll('.anotherCheckboxListServiceClicked input[type="radio"]');
    const secondCheckMarkOptionsSpan = document.querySelectorAll('.checkmark2');
    let secondCheckMarkSelected = false;
    secondCheckMarkOptions.forEach(option => {
      if (option.checked) {
        secondCheckMarkSelected = true;
        secondCheckMarkOptionsSpan.forEach(option => {
          option.classList.remove('errorHighlight');
        });
      }
    });
    if (!secondCheckMarkSelected) {
      alert("Selecione pelo menos uma opção por quantas horas deseja ser atendido!");
      const secondCheckMarkOptionsSpan = document.querySelectorAll('.checkmark2');
      secondCheckMarkOptionsSpan.forEach(option => {
        option.classList.add('errorHighlight');
      });
      return;
    }

    // alert("Serviço confirmado!");
    let url = `/${platform}/${action}/${encodedName}/${encodedStreet}/${encodedNumber}/${encodedComplement}/${encodedNeighborhood}/${encodedCity}/${encodedState}`
    if (encodedCep) url += `/${encodedCep}`;
    if (encodedWhatsApp) url += `/${encodedWhatsApp}`;
    if (encodedEmail) url += `/${encodedEmail}`;
    if (encodedSelectedOptionHour) url += `/${encodedSelectedOptionHour}`;
    window.location.href = url;

  } else {
    alert("Dados do primeiro formulário não estão corretos!");
  }
}

export { confirmService };