import { useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import InputGeneric from "../../components/inputGeneric";
import LabelGeneric from "../../components/labelGeneric";
import WhatsAppIcon from "../../components/whatsAppIcon";
import createAccountStyle from "./style.css";
import { handleChangeAddress } from "../../utils/handleChangeAddress";
import RecaptchaGoogle from "../../components/recaptchaGoogle";
import ButtonGeneric from "../../components/buttonGeneric";
import {
  confirmingAccount,
  registeringFunction,
} from "../../utils/registeringFunction";

export default function CreateYourAccount() {
  const [address, setAddress] = useState({
    name: "",
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    cep: "",
    whatsApp: "",
    email: "",
    password: "",
  });

  return (
    <div className={` ${createAccountStyle}`}>
      <Header
        thisHeaderStyle={`thisHeaderStyleBecomeDelivery`}
        divSeparingStyleHeader={`divSeparingStyleHeaderBecomeDelivery`}
      />
      <div className="beOurGreatDelivery">
        <h1>Crie sua conta e aproveite os nossos benefícios</h1>
      </div>
      <div className="backgroundOfServiceBecomeDelivery"></div>
      <h1 className="forHBecomeDelivery2">
        Se cadastre e tenha acesso a excelentes conteúdos
      </h1>
      <div className="adjustingDivForForDelivery">
        <form className="adjustingFormToBecameDelivery">
          <div className="adjustingLabelAndInput2Delivery">
            <LabelGeneric>
              <span className="textWithSameSize3Delivery">Nome: </span>
              <InputGeneric
                classTypeName="name"
                typeInput={"text"}
                valueInput={address.name}
                setValueInput={(value) =>
                  handleChangeAddress(setAddress, "name", value)
                }
                typeInputId="longestTextDelivery"
              />
            </LabelGeneric>
            <div className="adjustTogheter3Delivery">
              <LabelGeneric>
                <span className="textWithSameSize3Delivery">Endereço: </span>
                <InputGeneric
                  classTypeName="street"
                  typeInput={"text"}
                  valueInput={address.street}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "street", value)
                  }
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize3Delivery textWithSameSize4Delivery">
                  Número:{" "}
                </span>
                <InputGeneric
                  classTypeName="number"
                  typeInput={"text"}
                  valueInput={address.number}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "number", value)
                  }
                  typeInputId="shorterTextDelivery"
                />
              </LabelGeneric>
            </div>
            <div className="adjustTogheter3Delivery">
              <LabelGeneric>
                <span className="textWithSameSize3Delivery">Complemento: </span>
                <InputGeneric
                  classTypeName="complement"
                  typeInput={"text"}
                  valueInput={address.complement}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "complement", value)
                  }
                  typeInputId="mediumTextDelivery"
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize3Delivery textWithSameSize4Delivery">
                  Bairro:{" "}
                </span>
                <InputGeneric
                  classTypeName="neighborhood"
                  typeInput={"text"}
                  valueInput={address.neighborhood}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "neighborhood", value)
                  }
                  typeInputId="largeTextDelivery"
                />
              </LabelGeneric>
            </div>
            <div className="adjustTogheter3Delivery">
              <LabelGeneric>
                <span className="textWithSameSize3Delivery">Cidade: </span>
                <InputGeneric
                  classTypeName="city"
                  typeInput={"text"}
                  valueInput={address.city}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "city", value)
                  }
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize3Delivery textWithSameSize4Delivery">
                  Estado:{" "}
                </span>
                <InputGeneric
                  classTypeName="state"
                  typeInput={"text"}
                  valueInput={address.state}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "state", value)
                  }
                  typeInputId="shorterTextDelivery"
                />
              </LabelGeneric>
            </div>
            <div className="adjustAtStart3Delivery">
              <LabelGeneric>
                <span className="textWithSameSize3Delivery">CEP: </span>
                <InputGeneric
                  classTypeName="cep"
                  typeInput={"text"}
                  valueInput={address.cep}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "cep", value)
                  }
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize3Delivery">WhatsApp: </span>
                <InputGeneric
                  classTypeName="whatsApp"
                  typeInput={"text"}
                  valueInput={address.whatsApp}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "whatsApp", value)
                  }
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize3Delivery">Email: </span>
                <InputGeneric
                  classTypeName="email"
                  typeInput={"text"}
                  valueInput={address.email}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "email", value)
                  }
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize3Delivery">Senha: </span>
                <InputGeneric
                  classTypeName="password"
                  typeInput={"text"}
                  valueInput={address.password}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "password", value)
                  }
                />
              </LabelGeneric>
            </div>
            <div className="recaptchaAndCheckmarkLine3Delivery">
              <RecaptchaGoogle />
              <div className="thisCheckMarkStyling3Delivery"></div>
            </div>
            <ButtonGeneric
              inputType={"button"}
              onClick={() =>
                registeringFunction(confirmingAccount, "/accountCreatedSuccess")
              }
            >
              Criar Conta
            </ButtonGeneric>
          </div>
        </form>
      </div>
      <WhatsAppIcon />
      <Footer />
    </div>
  );
}
