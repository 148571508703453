import Footer from "../../components/footer";
import Header from "../../components/header";
import servicesStyle from "./style.css";
import { useParams } from "react-router-dom";
import friendPerson from "../../data/friendPerson";
import ButtonGeneric from "../../components/buttonGeneric";
import WhatsAppIcon from "../../components/whatsAppIcon";
import Map from "../../components/map";
import { hourChoosed } from "../../utils/hourChoosed";
import { changingPageWithClick } from "../../utils/changingPageWithClick";

export default function Services() {
  let hourRate = 90;
  const {
    platform,
    action,
    name,
    optionHour,
  } = useParams();

  // street,
  //   number,
  //   complement,
  //   neighborhood,
  //   city,
  //   state,
  //   cep,
  //   whatsApp,
  //   email,
  
  // console.log(useParams());
  // const userType = "Amigo";
  const userType = "Aluno";

  return (
    <>
      <div className={` ${servicesStyle}`}>
        <Header />
        <div className="containerTopServices">
          <div className="alignedTopServices">
            <h1>Seja bem Vindo {name} !</h1>
            <h2>
              Horas selecionadas : {optionHour}
              <div>Preço: {hourChoosed(optionHour,hourRate)}</div>
            </h2>
            <h1>
              Você é um: <strong>{userType}</strong>
            </h1>
            <h2>Plataforma: {platform}</h2>
            <h2>Ação: {action}</h2>
            {userType === "Amigo" ? (
              <h2>Você receberá R$ 65,00</h2>
            ) : (
              <>
                {/* <h2>Você pagará R$ 90,00</h2> */}
                <h1>
                  Esses são alguns amigos que recomendamos para esse serviço:
                </h1>
                <ul className="adjustFriendPersonList">
                  {friendPerson.map((friend) => (
                    <li className="possibleFriends" key={friend.imageUrl}>
                      <div className="servicesBlockImages">
                        <h3>{friend.name}</h3>
                        <div className="servicesAdjustingImages">
                          <div className="pictureFriends">
                            <img src={friend.imageUrl} alt={friend.name} />
                          </div>
                          <ButtonGeneric
                            onClick={() => changingPageWithClick(friendPerson,platform,action,friend.name)}
                          >
                            Escolher Amigo
                          </ButtonGeneric>
                        </div>
                        <p>{friend.especialits}</p>
                      </div>
                    </li>
                  ))}
                </ul>
                <Map />
              </>
            )}
          </div>
        </div>
        <WhatsAppIcon />
        <Footer />
      </div>
    </>
  );
}
