// import checkMarkStyle from './style.css';

// export default function CheckMarkGeneric({ options, className, onChange, classNameSpan, classInputNameCheckMark,classForAdjustLabel,optionGroup="",onClick}) {

//   return (
//     <div className={checkMarkStyle}>
//       <div className={`${className} checkbox-listed`}>
//           {options.map((option, index) => (
//             <label key={index} className={classForAdjustLabel}>
//               <input type="radio" name={optionGroup} value={option.value} onChange={onChange} onClick={onClick} className={`${classInputNameCheckMark}`} />
//               <span className={`checkmarked ${classNameSpan}`}></span>
//               {option.label}
//             </label>
//           ))}
//         </div>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import checkMarkStyle from "./style.css";
import { handleOptionChange } from "../../utils/handleOptionChange";

export default function CheckMarkGeneric({
  options,
  className,
  onChange,
  classNameSpan,
  classInputNameCheckMark,
  classForAdjustLabel,
  optionGroup = "",
  onClick,
  isChecked = "",
  id,
}) {
  const [selectedOption, setSelectedOption] = useState(
    (localStore) => localStore || localStorage.getItem(optionGroup),
  );

  // const handleOptionChange = (e) => {
  //   const { value } = e.target;
  //   setSelectedOption(value);
  // //   if(isChecked){
  // //   localStorage.setItem(optionGroup, value);
  // // }
  //   onChange && onChange(e);
  // };
  

  useEffect(() => {
    // const storedOption = localStorage.getItem(optionGroup);
    // if (isChecked === true) {
    //   setSelectedOption((prevOption) =>
    //     prevOption !== "" ? prevOption : storedOption,
    //   );
    // }
    if (isChecked) {
          setSelectedOption((selectedOption) => selectedOption || localStorage.getItem(optionGroup));
  }

  }, [optionGroup, isChecked]);

  return (
    <div className={checkMarkStyle}>
      <div className={`${className} checkbox-listed`}>
        {options.map((option, index) => (
          <label key={index} className={classForAdjustLabel}>
            <input
              type="radio"
              name={optionGroup}
              value={option.value}
              onChange={(e) => handleOptionChange(e,setSelectedOption,onChange,isChecked,optionGroup)}
              onClick={onClick}
              className={`${classInputNameCheckMark}`}
              checked={option.value === selectedOption}
              id={id}
            />
            <span className={`checkmarked ${classNameSpan}`}></span>
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
}
