import coursesStyle from "./style.css";
import { useParams } from "react-router-dom";
import WhatsAppIcon from "../../components/whatsAppIcon";
import { useState } from "react";
import { searchCourse } from "../../utils/searchCourse";
import { useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ReactPlayer from 'react-player/youtube';
import { backgroundChange } from "../../utils/backgroundChange";

export default function CoursesPlatform() {
  const { coursesPlatform } = useParams();
  const [searchCourseResults, setSearchCourseResults] = useState([]);
  const [searchCoursePlatform, setSearchCoursePlatform] = useState(null);
  const [clickedVideo, setClickedVideo] = useState(null);

  useEffect(() => {
    searchCourse(
      coursesPlatform,
      setSearchCoursePlatform,
      setSearchCourseResults,
    );
  }, [coursesPlatform, setSearchCoursePlatform, setSearchCourseResults]);

  return (
    <div className={`${coursesStyle}`} onLoad={() => backgroundChange(coursesPlatform)}>
      <div className="backgroundForThisCourse">
        <Header />
      </div>
      {searchCoursePlatform ? (
        <div>
          <ul>
            <h1>{coursesPlatform}</h1>
            <div className="adjustingAllVideosCourse">
              <div className="adjustingVideosCourses">
                {searchCourseResults.map((course) => (
                  <li key={course.title} className={`adjustingInsideCourse`}>
                    <h2>{course.title}</h2>
                    <div className="videoContainer">
                      <img
                        src={course.thumbnailUrl}
                        alt={`Thumbnail for ${course.title}`}
                        className="overlay"
                        id={`overlay-${course.title}`}
                        onClick={() => {
                          const image = document.getElementById(
                            `overlay-${course.title}`,
                          );
                          if (image) {
                            image.style.display = "none";
                            setClickedVideo(course.title);
                          }
                        }}
                      />

                      <ReactPlayer
                        className='react-player'
                        url={`${course.courseVideo.replace("watch?v=", "embed/")}`}
                        width='100%'
                        height='185px'
                        playing={clickedVideo === course.title}
                        controls={true}
                      />
                    </div>
                    <div className="textWidthAdjusting">
                      <p>{course.aboutVideo}</p>
                    </div>
                  </li>
                ))}
              </div>
            </div>
          </ul>
        </div>
      ) : (
        <div>Nenhum curso encontrado para a plataforma {coursesPlatform}</div>
      )}
      <WhatsAppIcon />
      <Footer />
    </div>
  );
}
