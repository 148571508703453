import { useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import InputGeneric from "../../components/inputGeneric";
import LabelGeneric from "../../components/labelGeneric";
import WhatsAppIcon from "../../components/whatsAppIcon";
import studentStyle from "./style.css";
import { handleChangeAddress } from "../../utils/handleChangeAddress";
import RecaptchaGoogle from "../../components/recaptchaGoogle";
import ButtonGeneric from "../../components/buttonGeneric";
import { confirmingAccount, registeringFunction } from "../../utils/registeringFunction";

export default function BecomeAStudent() {
  const [address, setAddress] = useState({
    name: "",
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    cep: "",
    whatsApp: "",
    email: "",
    password: "",
  });

  return (
    <div className={` ${studentStyle} fullBecomeStudent`}>
      <Header burguerMenuIsOpenClass={`burguerMenuIsOpenClass burguerMenuIsOpenClassOpnened burguerMenuIsOpenClassFullTry`}
        thisHeaderStyle={`thisHeaderStyleBecomeStudent`}
        divSeparingStyleHeader={`divSeparingStyleHeaderBecomeStudent`}
        classNameHamburguerMenu={`classNameHamburguerMenuBecomeStudent`}
      />
      <div className="beOurGreatStudent">
        <h1>Seja um dos nossos grandiosos alunos</h1>
      </div>
      <div className="backgroundOfServiceBecomeStudent"></div>
      <div className="adjustingImageAndTitleBecomeStudent">
        <img src="/studentSimbollBecomeStudent.png" alt="" className="studentSimbollBecomeStudent" />
        <h1 className="forHBecomeStudent2">Torne-se um aluno</h1>
      </div>
      <div className="adjustingDivForForStudent">
        <form className="adjustingFormToBecameStudent">
          <div className="adjustingLabelAndInput2">
            <LabelGeneric>
              <span className="textWithSameSize3">Nome: </span>
              <InputGeneric
                classTypeName="name"
                typeInput={"text"}
                valueInput={address.name}
                setValueInput={(value) =>
                  handleChangeAddress(setAddress, "name", value)
                }
                typeInputId="longestTextStudent"
              />
            </LabelGeneric>
            <div className="adjustTogheter3">
              <LabelGeneric>
                <span className="textWithSameSize3">Endereço: </span>
                <InputGeneric
                  classTypeName="street"
                  typeInput={"text"}
                  valueInput={address.street}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "street", value)
                  }
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize3 textWithSameSize4">
                  Número:{" "}
                </span>
                <InputGeneric
                  classTypeName="number"
                  typeInput={"text"}
                  valueInput={address.number}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "number", value)
                  }
                  typeInputId="shorterTextStudent"
                />
              </LabelGeneric>
            </div>
            <div className="adjustTogheter3">
              <LabelGeneric>
                <span className="textWithSameSize3">Complemento: </span>
                <InputGeneric
                  classTypeName="complement"
                  typeInput={"text"}
                  valueInput={address.complement}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "complement", value)
                  }
                  typeInputId="mediumTextStudent"
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize3 textWithSameSize4">
                  Bairro:{" "}
                </span>
                <InputGeneric
                  classTypeName="neighborhood"
                  typeInput={"text"}
                  valueInput={address.neighborhood}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "neighborhood", value)
                  }
                  typeInputId="largeTextStudent"
                />
              </LabelGeneric>
            </div>
            <div className="adjustTogheter3">
              <LabelGeneric>
                <span className="textWithSameSize3">Cidade: </span>
                <InputGeneric
                  classTypeName="city"
                  typeInput={"text"}
                  valueInput={address.city}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "city", value)
                  }
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize3 textWithSameSize4">
                  Estado:{" "}
                </span>
                <InputGeneric
                  classTypeName="state"
                  typeInput={"text"}
                  valueInput={address.state}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "state", value)
                  }
                  typeInputId="shorterTextStudent"
                />
              </LabelGeneric>
            </div>
            <div className="adjustAtStart3">
              <LabelGeneric>
                <span className="textWithSameSize3">CEP: </span>
                <InputGeneric
                  classTypeName="cep"
                  typeInput={"text"}
                  valueInput={address.cep}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "cep", value)
                  }
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize3">WhatsApp: </span>
                <InputGeneric
                  classTypeName="whatsApp"
                  typeInput={"text"}
                  valueInput={address.whatsApp}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "whatsApp", value)
                  }
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize3">Email: </span>
                <InputGeneric
                  classTypeName="email"
                  typeInput={"text"}
                  valueInput={address.email}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "email", value)
                  }
                />
              </LabelGeneric>
              <LabelGeneric>
                <span className="textWithSameSize3">Senha: </span>
                <InputGeneric
                  classTypeName="password"
                  typeInput={"text"}
                  valueInput={address.password}
                  setValueInput={(value) =>
                    handleChangeAddress(setAddress, "password", value)
                  }
                />
              </LabelGeneric>
            </div>
            <div className="recaptchaAndCheckmarkLine3">
              <RecaptchaGoogle />
              <div className="thisCheckMarkStyling3"></div>
            </div>
            <ButtonGeneric inputType={"button"} onClick={() => registeringFunction(confirmingAccount, "/accountCreatedSuccess")}>Criar Conta</ButtonGeneric>
          </div>
        </form>
      </div>
      <WhatsAppIcon />
      <Footer />
    </div>
  );
}
