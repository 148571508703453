const courses = [
  {
    coursePlatform: "Instagram",
    backgroundThisCourse: "https://cdn.pixabay.com/photo/2018/04/14/17/57/instagram-3319588_1280.jpg",
    imageUrl:
      "/instagramCourse.png",
    courseActions: [
      { title: "5 dicas do Instagram", courseVideo: "https://www.youtube.com/watch?v=26ouMpQpWic", aboutVideo:" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit architecto laudantium quaerat delectus ratione rem hic culpa porro. Obcaecati ad voluptates adipisci?",thumbnailUrl:"https://cdn.pixabay.com/photo/2020/04/04/04/23/media-5000790_640.png", },
      { title: "Como crescer no Instagram", courseVideo: "https://www.youtube.com/watch?v=i1cdD28ozf0", aboutVideo:" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit architecto laudantium quaerat delectus ratione rem hic culpa porro. Obcaecati ad voluptates adipisci?",thumbnailUrl:"https://cdn.pixabay.com/photo/2020/04/04/04/23/media-5000790_640.png", },
      { title: "Como deletar sua conta no instagram", courseVideo: "https://www.youtube.com/watch?v=Sc2O9AGJT90", aboutVideo:" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit architecto laudantium quaerat delectus ratione rem hic culpa porro. Obcaecati ad voluptates adipisci?",thumbnailUrl:"https://cdn.pixabay.com/photo/2019/11/30/15/49/instagram-4663472_640.png", },
      { title: "5 dicas do Instagram 2", courseVideo: "https://www.youtube.com/watch?v=26ouMpQpWic", aboutVideo:" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit architecto laudantium quaerat delectus ratione rem hic culpa porro. Obcaecati ad voluptates adipisci?",thumbnailUrl:"https://cdn.pixabay.com/photo/2020/04/04/04/23/youtube-5000792_640.png", },
      { title: "Como crescer no Instagram 2", courseVideo: "https://www.youtube.com/watch?v=i1cdD28ozf0", aboutVideo:" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit architecto laudantium quaerat delectus ratione rem hic culpa porro. Obcaecati ad voluptates adipisci?",thumbnailUrl:"https://cdn.pixabay.com/photo/2020/05/27/16/33/podcast-5227930_640.png", },
      { title: "Como deletar sua conta no instagram 2", courseVideo: "https://www.youtube.com/watch?v=Sc2O9AGJT90", aboutVideo:" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit architecto laudantium quaerat delectus ratione rem hic culpa porro. Obcaecati ad voluptates adipisci?",thumbnailUrl:"https://cdn.pixabay.com/photo/2020/09/01/22/53/followers-5536934_640.png", },
      { title: "Como crescer no Instagram 3", courseVideo: "https://www.youtube.com/watch?v=i1cdD28ozf0", aboutVideo:" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit architecto laudantium quaerat delectus ratione rem hic culpa porro. Obcaecati ad voluptates adipisci?",thumbnailUrl:"https://cdn.pixabay.com/photo/2020/09/01/22/53/followers-5536934_640.png", },
      { title: "Como deletar sua conta no instagram 3", courseVideo: "https://www.youtube.com/watch?v=Sc2O9AGJT90", aboutVideo:" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit architecto laudantium quaerat delectus ratione rem hic culpa porro. Obcaecati ad voluptates adipisci?",thumbnailUrl:"https://cdn.pixabay.com/photo/2020/09/01/22/53/followers-5536934_640.png", },
    ],
  },
  {
    coursePlatform: "Facebook",
    backgroundThisCourse: "https://cdn.pixabay.com/photo/2018/04/16/10/27/facebook-3324207_1280.jpg",
    imageUrl:
      "/facebookCourse.png",
    courseActions: [
      { title: "5 dicas do Facebook", courseVideo: "https://www.youtube.com/watch?v=71UXWC_hDgw", aboutVideo:" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit architecto laudantium quaerat delectus ratione rem hic culpa porro. Obcaecati ad voluptates adipisci?",thumbnailUrl:"https://cdn.pixabay.com/photo/2021/12/23/09/17/internet-6889040_640.png", },
      { title: "Como crescer no Facebook", courseVideo: "https://www.youtube.com/watch?v=BszYeoeiHF0", aboutVideo:" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit architecto laudantium quaerat delectus ratione rem hic culpa porro. Obcaecati ad voluptates adipisci?",thumbnailUrl:"https://cdn.pixabay.com/photo/2017/10/29/01/24/social-networking-icon-2898674_640.png", },
      { title: "Como deletar sua conta no Facebook", courseVideo: "https://www.youtube.com/watch?v=9H1M6YtIhz8", aboutVideo:" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit architecto laudantium quaerat delectus ratione rem hic culpa porro. Obcaecati ad voluptates adipisci?",thumbnailUrl:"https://cdn.pixabay.com/photo/2015/07/20/11/24/icon-852635_640.png", },
    ],
  },
  {
    coursePlatform: "Twitter",
    backgroundThisCourse: "https://cdn.pixabay.com/photo/2018/04/14/18/06/twitter-3319619_960_720.jpg",
    imageUrl:
      "/twitterCourse.png",
    courseActions: [
      { title: "5 dicas do Twitter", courseVideo: "https://www.youtube.com/watch?v=dlypTeMrY80", aboutVideo:" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit architecto laudantium quaerat delectus ratione rem hic culpa porro. Obcaecati ad voluptates adipisci?",thumbnailUrl:"https://cdn.pixabay.com/photo/2020/04/04/04/23/media-5000790_640.png", },
      { title: "Como crescer no Twitter", courseVideo: "https://www.youtube.com/watch?v=ymgbzTQA4gA", aboutVideo:" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit architecto laudantium quaerat delectus ratione rem hic culpa porro. Obcaecati ad voluptates adipisci?",thumbnailUrl:"https://cdn.pixabay.com/photo/2020/07/28/17/04/facebook-5445671_640.png", },
      { title: "Como deletar sua conta no Twitter", courseVideo: "https://www.youtube.com/watch?v=2D7CL5rjGDs", aboutVideo:" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit architecto laudantium quaerat delectus ratione rem hic culpa porro. Obcaecati ad voluptates adipisci?",thumbnailUrl:"https://cdn.pixabay.com/photo/2017/06/23/02/32/instagram-2433265_640.png", },
    ],
  },
];

export default courses;
