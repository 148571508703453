import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import RecaptchaGoogleStyle from "./style.css";
import { handleRecaptchaChangeFirst } from "../../utils/handleRecaptcha";

export default function RecaptchaGoogle({ classTypeName }) {
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  return (
    <div className={`${RecaptchaGoogleStyle} ${classTypeName}`}>
      <ReCAPTCHA
        className="recaptchaStyle"
        sitekey="6LfjbNgpAAAAAKDxMa7phTDub9zR5IghE2HC_Tz2"
        onChange={() => handleRecaptchaChangeFirst(setRecaptchaToken)}
      />
    </div>
  );
}
