const changeVisibilityCheckingEmail = (isEmailAlreadyRegistered, setIsCoursesShowing, isCoursesShowing) => {
  let element = document.querySelector(".blockTextForEmailError");
  let elementError = document.querySelector(".blockTextForEmail");
  let elementErrorNoText = document.querySelector(".blockTextForEmailErrorNoText");
  if (element) {
    element.remove();
  }
  if (elementError) {
    elementError.remove();
  }
  if (elementErrorNoText) {
    elementErrorNoText.remove();
  }
  if (document.querySelector('.inputInvitingToCoursesEmail').value !== '') {
    if (isEmailAlreadyRegistered === true) {
      setIsCoursesShowing(isCoursesShowing = true);
      let blockText = document.createElement("p");
      blockText.classList.add("blockTextForEmail");
      blockText.innerText = "Email registrado! Prossiga para o pagamento.";
      document.querySelector(".anotherMessageToShowEmail").appendChild(blockText);
      return;
    } else {
      setIsCoursesShowing(isCoursesShowing = false);
      let blockError = document.createElement("p");
      blockError.classList.add("blockTextForEmailError");
      blockError.innerHTML = "<p>Email não registrado! Clique <a href='/createYourAccount'>aqui</a> para criar uma conta.<p>";
      document.querySelector(".anotherMessageToShowEmail").appendChild(blockError);
    }
    return;
  }
  setIsCoursesShowing(isCoursesShowing = false);
  let blockErrorNoText = document.createElement("p");
  blockErrorNoText.classList.add("blockTextForEmailErrorNoText");
  blockErrorNoText.innerHTML = "<p>Por Favor, preencha o campo de email corretamente!<p>";
  document.querySelector(".anotherMessageToShowEmail").appendChild(blockErrorNoText);
};

export { changeVisibilityCheckingEmail };