const handleOptionChangeCheckmark = (selectedValue,optionsCheckmark,setSelectedOptionCheckmark) => {
  const selectedOption = optionsCheckmark.find(option => option.value === selectedValue);
  
  if (selectedOption) {
    setSelectedOptionCheckmark(selectedOption.label);
    console.log(selectedOption.label);
  }
};

export {handleOptionChangeCheckmark};
