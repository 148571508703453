const handleOptionChange = (e,setSelectedOption,onChange,isChecked,optionGroup) => {
  const { value } = e.target;
  setSelectedOption(value);
  if(isChecked){
  localStorage.setItem(optionGroup, value);
}
  onChange && onChange(e);
  console.log(onChange);
};

export {handleOptionChange};