import { doTheMath } from "../doTheMath";

const hourChoosed = (optionHour,hourRate) => {
  let multiplier = "";
  switch (optionHour) {
    case "30 minutos":
      multiplier = 1/2;
      break; 
  case "1 hora":
      multiplier = 1;
      break;
      case "2 horas":
      multiplier = 2;
      break;
      case "3 horas":
      multiplier = 3;
      break;
    default:
      multiplier=0;
      break;
  }
  return doTheMath(hourRate, multiplier);
}

export {hourChoosed};