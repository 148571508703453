import { useParams } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import serviceClickedStyle from "./style.css";
import InputGeneric from "../../components/inputGeneric";
import { useEffect, useState } from "react";
import LabelGeneric from "../../components/labelGeneric";
import { handleChangeAddress } from "../../utils/handleChangeAddress";
import ButtonGeneric from "../../components/buttonGeneric";
import CheckMarkGeneric from "../../components/checkMarkGeneric";
import RecaptchaGoogle from "../../components/recaptchaGoogle";
import { checkForm } from "../../utils/checkForm";
import { confirmingService } from "../../utils/checkForm";
import { confirmService } from "../../utils/confirmService";
import { handleOptionChangeCheckmark } from "../../utils/handleOptionChangeCheckmark";
import { changeVisibleElement } from "../../utils/changeVisibleElement";
import { handleSaveToLocalStorage } from "../../utils/handleSaveToLocalStorage";

export default function WhenServiceIsClicked() {
  const { platform, action } = useParams();
  const [selectedOptionHour, setSelectedOptionHour] = useState(null);
  const [visibleElement, setVisibleElement] = useState(true);
  const [address, setAddress] = useState({
    name: "",
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    cep: "",
    whatsApp: "",
    email: "",
  });

  const encodedName = encodeURIComponent(address.name);
  const encodedStreet = encodeURIComponent(address.street);
  const encodedNumber = encodeURIComponent(address.number);
  const encodedComplement = encodeURIComponent(address.complement);
  const encodedNeighborhood = encodeURIComponent(address.neighborhood);
  const encodedCity = encodeURIComponent(address.city);
  const encodedState = encodeURIComponent(address.state);
  const encodedCep = address.cep ? encodeURIComponent(address.cep) : "";
  const encodedWhatsApp = encodeURIComponent(address.whatsApp);
  const encodedEmail = address.email ? encodeURIComponent(address.email) : "";
  const encodedSelectedOptionHour = selectedOptionHour
    ? encodeURIComponent(selectedOptionHour)
    : "";
  const optionsSO = [
    { value: "option1", label: "Android" },
    { value: "option2", label: "iOS" },
    { value: "option3", label: "Outros" },
  ];
  const optionsHour = [
    { value: "option1", label: "30 minutos" },
    { value: "option2", label: "1 hora" },
    { value: "option3", label: "2 horas" },
    { value: "option4", label: "3 horas" },
  ];
  const optionsHourRenew = [
    { value: "option1", label: "30 minutos" },
    { value: "option2", label: "1 hora" },
    { value: "option3", label: "2 horasﾠﾠﾠ" },
    { value: "option4", label: "3 horas" },
  ];
  const optionToSave = [{ value: "option1", label: "Salvar informações" }];
  const [selectedOptionSO, setSelectedOptionSO] = useState(null);
  const [selectedOptionToSave, setSelectedOptionToSave] = useState(null);
  let [isChecked,setIsChecked] = useState(false);

//   const handleSaveToLocalStorage = () => {
//     setIsChecked(isChecked = true);
//     const addressData = JSON.stringify(address);
//     // const soData = JSON.stringify(
//     //   optionsSO.find((option) => option.label === selectedOptionSO),
//     // );
// //     localStorage.setItem('groupSO', soData);
// // console.log(soData)
//     localStorage.setItem("addressData", addressData);
//     // localStorage.setItem("selectedOption", soData);
//   };

  useEffect(() => {
    
    const loadFromLocalStorage = () => {
      const storedAddressData = localStorage.getItem("addressData");
      // const storedSelectedOption = localStorage.getItem("selectedOption");
      // console.log(localStorage.getItem("selectedOption"));

      if (storedAddressData) {
        const parsedAddressData = JSON.parse(storedAddressData);
        // const parsedSelectedOption = JSON.parse(storedSelectedOption);
        // console.log(parsedSelectedOption.value);
        setAddress(parsedAddressData);
        // setSelectedOptionSO(parsedSelectedOption.label);
      }
    };
    loadFromLocalStorage();
  }, []);

  return (
    <div className={`${serviceClickedStyle}`}>
      <Header />
      <div className="adjustingThisDivPadding">
        <div className="adjustingThisH">
          <h1>Plataforma: {platform}</h1>
          <h2>Ação: {action}</h2>
          <h2 className="paddingForThisH2">
            Coloque o endereço que deseja ser atendido
          </h2>
        </div>
        <div className="centeringThisThings">
          <div className="adjustingBlocksHour2">
            <form id="myForm">
              <div className="adjustingLabelAndInputServiceClicked">
                <LabelGeneric styledLabel={`styledLabelForService`}>
                  <span className="textWithSameSize">Nome: </span>
                  <InputGeneric
                    classTypeName="name"
                    typeInput={"text"}
                    valueInput={address.name}
                    setValueInput={(value) =>
                      handleChangeAddress(setAddress, "name", value)
                    }
                    typeInputId="longestText"
                  />
                </LabelGeneric>
                <div className="adjustTogheter">
                  <LabelGeneric styledLabel={`styledLabelForService`}>
                    <span className="textWithSameSize">Endereço: </span>
                    <InputGeneric
                      classTypeName="street"
                      typeInput={"text"}
                      valueInput={address.street}
                      setValueInput={(value) =>
                        handleChangeAddress(setAddress, "street", value)
                      }
                    />
                  </LabelGeneric>
                  <LabelGeneric styledLabel={`litleStyledService`}>
                    <span className="textWithSameSize textWithSameSize2 textWithLowSizeService">
                      Número:{" "}
                    </span>
                    <InputGeneric
                      classTypeName="number"
                      typeInput={"text"}
                      valueInput={address.number}
                      setValueInput={(value) =>
                        handleChangeAddress(setAddress, "number", value)
                      }
                      typeInputId="shorterText"
                    />
                  </LabelGeneric>
                </div>
                <div className="adjustTogheter">
                  <LabelGeneric styledLabel={`styledLabelForService`}>
                    <span className="textWithSameSize">Complemento: </span>
                    <InputGeneric
                      classTypeName="complement"
                      typeInput={"text"}
                      valueInput={address.complement}
                      setValueInput={(value) =>
                        handleChangeAddress(setAddress, "complement", value)
                      }
                      typeInputId="mediumText"
                    />
                  </LabelGeneric>
                  <LabelGeneric styledLabel={`styledLabelForService`}>
                    <span className="textWithSameSize textWithSameSize2">
                      Bairro:{" "}
                    </span>
                    <InputGeneric
                      classTypeName="neighborhood"
                      typeInput={"text"}
                      valueInput={address.neighborhood}
                      setValueInput={(value) =>
                        handleChangeAddress(setAddress, "neighborhood", value)
                      }
                      typeInputId="largeText"
                    />
                  </LabelGeneric>
                </div>
                <div className="adjustTogheter">
                  <LabelGeneric styledLabel={`styledLabelForService`}>
                    <span className="textWithSameSize">Cidade: </span>
                    <InputGeneric
                      classTypeName="city"
                      typeInput={"text"}
                      valueInput={address.city}
                      setValueInput={(value) =>
                        handleChangeAddress(setAddress, "city", value)
                      }
                    />
                  </LabelGeneric>
                  <LabelGeneric styledLabel={`litleStyledService`}>
                    <span className="textWithSameSize textWithSameSize2 textWithLowSizeService">
                      Estado:{" "}
                    </span>
                    <InputGeneric
                      classTypeName="state"
                      typeInput={"text"}
                      valueInput={address.state}
                      setValueInput={(value) =>
                        handleChangeAddress(setAddress, "state", value)
                      }
                      typeInputId="shorterText"
                    />
                  </LabelGeneric>
                </div>
                <div className="adjustAtStart">
                  <LabelGeneric styledLabel={`styledLabelForService`}>
                    <span className="textWithSameSize">CEP (opcional): </span>
                    <InputGeneric
                      typeInput={"text"}
                      valueInput={address.cep}
                      setValueInput={(value) =>
                        handleChangeAddress(setAddress, "cep", value)
                      }
                    />
                  </LabelGeneric>
                  <LabelGeneric styledLabel={`styledLabelForService`}>
                    <span className="textWithSameSize">WhatsApp: </span>
                    <InputGeneric
                      classTypeName="whatsApp"
                      typeInput={"text"}
                      valueInput={address.whatsApp}
                      setValueInput={(value) =>
                        handleChangeAddress(setAddress, "whatsApp", value)
                      }
                    />
                  </LabelGeneric>
                  <LabelGeneric styledLabel={`styledLabelForService`}>
                    <span className="textWithSameSize">Email (opcional): </span>
                    <InputGeneric
                      typeInput={"text"}
                      valueInput={address.email}
                      setValueInput={(value) =>
                        handleChangeAddress(setAddress, "email", value)
                      }
                    />
                  </LabelGeneric>
                </div>
                <CheckMarkGeneric
                  options={optionsSO}
                  optionGroup="groupSO"
                  className="checkbox-list"
                  classNameSpan={`checkmark`}
                  isChecked={isChecked}
                  onChange={(e) =>
                    handleOptionChangeCheckmark(
                      e.target.value,
                      optionsSO,
                      setSelectedOptionSO,
                    )
                  }
                />
                <div className="recaptchaAndCheckmarkLine" on>
                  <RecaptchaGoogle classTypeName={`recaptchaGoogleServiceClicked`}/>
                  <div className="thisCheckMarkStyling">
                    <CheckMarkGeneric
                      options={optionToSave}
                      onChange={(e) =>
                        handleOptionChangeCheckmark(
                          e.target.value,
                          optionToSave,
                          setSelectedOptionToSave,
                        )
                      }
                      optionGroup="groupSave"
                      onClick={()=>handleSaveToLocalStorage(setIsChecked,isChecked,address)}
                    />
                  </div>
                </div>
                <ButtonGeneric
                  inputType={"button"}
                  onClick={() => checkForm(confirmingService)}
                  classType={`buttonForVerifyData`}
                >
                  Verificar dados
                </ButtonGeneric>
              </div>
            </form>
            <h2 className="adjustingBlocksHour2">
              Selecione a quantidade de horas que deseja ser atendido:
            </h2>
            <div className="adjustingBothFormsDiferent">
              <form className="adjustingLabelAndInputServiceClicked adjustingBlocksHour3">
                <CheckMarkGeneric
                  options={optionsHour}
                  classNameSpan={`checkmark2`}
                  className="anotherCheckboxListServiceClicked"
                  optionGroup="groupHour"
                  onChange={(e) =>
                    handleOptionChangeCheckmark(
                      e.target.value,
                      optionsHour,
                      setSelectedOptionHour,
                    )
                  }
                />

                <ButtonGeneric classType={`buttonClassForServiceClicked`}
                  inputType={"button"}
                  onClick={() =>
                    confirmService(
                      confirmingService,
                      platform,
                      action,
                      encodedName,
                      encodedStreet,
                      encodedNumber,
                      encodedComplement,
                      encodedNeighborhood,
                      encodedCity,
                      encodedState,
                      encodedCep,
                      encodedWhatsApp,
                      encodedEmail,
                      encodedSelectedOptionHour,
                    )
                  }
                >
                  Confirmar Serviço
                </ButtonGeneric>
              </form>
              <form className="anotherFormToAdjust">
                <div className="adjustingBooleanOption">
                  <h3
                    onClick={() =>
                      changeVisibleElement(setVisibleElement, visibleElement)
                    }
                    className="efectTextShadow"
                  >
                    Clique aqui para renovar mais tempo com seu amigo digital
                  </h3>
                </div>
                {!visibleElement ? (
                  <CheckMarkGeneric
                    classForAdjustLabel={`classForAdjustLabelChec`}
                    className={
                      "changingPositionOfCheckMark letChangeThisDisplay"
                    }
                    options={optionsHourRenew}
                    optionGroup="groupHourRenew"
                    classInputNameCheckMark={`checkmarkClassForOrange`}
                    id={`idForOrangeClass`}
                  />
                ) : (
                  <div></div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
