const friendPerson = [
  {
    name: "José",
    imageUrl:
      "https://img.freepik.com/fotos-gratis/retrato-de-homem-bonito-e-serio_23-2149022618.jpg?w=740",
    especialits:
      "Especialista em Instagram Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit",
    position: [-23.54632, -46.62742],
  },
  {
    name: "Maria",
    imageUrl:
      "https://img.freepik.com/fotos-gratis/retrato-de-uma-linda-mulher-morena-rosto-sorridente_53876-137688.jpg?w=740",
    especialits:
      "Especialista em Twitter Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit",
    position: [-23.54636, -46.62867],
  },
  {
    name: "Tereza",
    imageUrl:
      "https://img.freepik.com/fotos-gratis/close-up-mulher-morena-olhando-para-a-camera-sobre-cinza_171337-1000.jpg?w=740",
    especialits:
      "Especialista em Facebook Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam quibusdam odit velit cupiditate quos vero eveniet, reprehenderit",
    position: [-23.5464, -46.63],
  },
];

export default friendPerson;
