const services = [
  {
    platform: "Instagram",
    imagePlatform: "/instagramFooter.png",
    actions: [
      { title: "Como usar o Instagram" },
      { title: "Como postar uma foto no Instagram" },
      { title: "Como excluir uma postagem no Instagram" },
    ],
  },
  {
    platform: "Facebook",
    imagePlatform: "/facebookFooter.png",
    actions: [
      { title: "Como usar o Facebook" },
      { title: "Como fazer uma publicação no Facebook" },
      { title: "Como excluir uma publicação no Facebook" },
    ],
  },
  {
    platform: "Twitter",
    imagePlatform: "/twitterFooter.png",
    actions: [
      { title: "Como usar o Twitter" },
      { title: "Como twittar no Twitter" },
      { title: "Como excluir um tweet no Twitter" },
    ],
  },
];

export default services;
