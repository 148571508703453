/* global grecaptcha */
let confirmingService = '';
const checkForm = () => {
  const fields = [
    "name",
    "street",
    "number",
    "complement",
    "neighborhood",
    "city",
    "state",
    "whatsApp"
  ];
  const form = document.querySelector('.myForm');// eslint-disable-line no-unused-vars, @typescript-eslint/no-unused-vars
  let hasError = false;

  fields.forEach(field => {
    const inputField = document.querySelector(`.${field}`);
    if (inputField) {
      const value = inputField.value.trim();
      if (!value && field !== "cep" && field !== "email") {
        hasError = true;
        inputField.classList.add("error");
      } else {
        inputField.classList.remove("error");
      }
    } else {
      console.error(`Elemento "${field}" não encontrado.`);
    }
  });

  const checkMarkOptions = document.querySelectorAll('.checkbox-list input[type="radio"]');
  const checkMarkOptionsSpan = document.querySelectorAll(".checkmark");
  let checkMarkSelected = false;
  checkMarkOptions.forEach(option => {
    if (option.checked) {
      checkMarkSelected = true;
      checkMarkOptionsSpan.forEach(option =>{
        option.classList.remove('errorHighlight');
      });  
    };
  });

  if (!checkMarkSelected) {
    alert("Dados faltando e/ou opção não selecionada!");
    const checkMarkOptionsSpan = document.querySelectorAll(".checkmark");
    checkMarkOptionsSpan.forEach(option =>{
      option.classList.add('errorHighlight');
    });
    return;
  }

  const recaptchaValue = grecaptcha.getResponse();
  if (!recaptchaValue) {
    alert("Por favor, selecione o reCAPTCHA!");
    return;
  }

  if (hasError) {
    alert("Dados faltando!");
    confirmingService = "Dados faltando!"; 
  } else {
    // alert("Dados corretos!");
    confirmingService = "Dados corretos!"; 
    const scrollPosition = window.scrollY;
    window.scrollTo({
      top: scrollPosition + 300,
      behavior: 'smooth'
    });
  }
}

export {checkForm, confirmingService}